.ajouter,.retirer{
  width:16px;
  height:100%;
}
.ajouter{
  width:22px;
}
/* .publicName_icons{
  flex-flow:row wrap;
  justify-content:space-between;
  align-items:center;
} */
/* .publicName_icons{
  display:flex;
  flex-flow:row wrap;
  justify-content:space-between;
  align-items:center;
} */
/* .supprimer{
  display:none;
} */
