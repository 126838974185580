.Tablette_pc{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: auto;
}

.tronc{
    background: rgb(255, 255, 255);
    width: 70%;
    height: auto;
    margin: 0px;
    margin-left: 1%;
    box-shadow: -10px -10px 8px rgba(0, 0, 0, 0.15), 10px 0px 8px rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    }
    .containerOnly{
        margin:0;
        padding:0;
        height:100%;
        width:100%;
        display:none;
        }
    .infos_large{
        margin-top: 0px;
        margin-left: 0px;
        width: 95%;
        }
        .infos_large p{
            margin:0.5em 2em;
            width: 95%;
            text-transform: underline;
            }
            /* .infos_large p::before{
                content: "";
                background: rgba(255, 0 ,0,.5);
                display:inline-block;
                width:1em;
                height:1em; 
                margin: .8em;
                margin-bottom: 0px;                      
                } */
        .infos_large hr{
            width: 95%;
            margin:0.8em 2em;
            }
    .text{
        margin: 0px;
        }
        .text p{
            margin:0.8em 5vw;
            text-align:justify;
            color: rgba(0, 0, 0, 0.8);
            margin-top: 18px;
            font-size: 20px;
            }
        .text img{
            float: left;
            width: 79vw;
            height: 79vw;
            margin-right: 1em;
            }
        iframe{
            z-index:0;
            float: left;
            width:95vw;
            height:85vh;
            margin:2vw;
            } 
@media screen and (max-width:1000px){
          iframe{
            width:95vw;
            height:95vw;
          } }

@media screen and (max-width:700px) {
    .Tablette_pc{
        flex-direction: column-reverse;
        width: 100%;
    }
    .tronc{
        float: left;
        background: rgb(255, 255, 255);
        width: 100%;
        height: 97%;
        margin:0%;
        }     
    }
@media screen and (min-width:700px) and (max-width:1300px) {
    .Tablette_pc{
        flex-direction: row-reverse;
        width: 100%;
    }
    .tronc{
            float: left;
            background: rgb(255, 255, 255);
            width: 70%;
            height: 97%;
            margin-left: 1.5%;
            margin-right: 0%;
            /* box-shadow: -10px 3px -10px rgba(0, 0, 0, 0.1); */
            }           
    }                     