#rs{
    width:11%;
    max-width: 300px;
    background-color:rgba(0,0,0,.2);
    border-bottom-right-radius:20px;
    border-top-right-radius:20px;
    box-shadow:2px 0px 4px rgba(0,0,0,.2),-2px -1px 2px rgba(0,0,0,.2);
   
    background-color:rgba(250,250,250,.2); 
    border-top:1px solid grey;
    color: #000;
    padding:2% 0px;
    margin:auto;
    margin-right:0px;
    margin-top: 6rem;
    }
    #rs a img{
        width:30px;
        height:100%;
        border:1px solid white;
        padding:2px;
        margin:2px;
        border-radius: 50%;
        }
        #lrs{
            width:100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap:2rem;
            list-style-type:none;
            margin:0px;
            padding: 0px;

            margin-top:7px;
            margin-bottom:0px;
            max-width: 500px;
            margin:auto;
           
            }
            #lrs li{
                width:25%;
                color: #000;
                text-align: center;
                }
                #rs a{
                    display:inline-block;
                    height:100%;
                    width:100%;
                    color: rgba(0, 0, 0, 1);
                    text-shadow: .5px .5px white;
                    letter-spacing: 2px;
                    line-height: 2em;
                    text-align: center;
                    text-decoration: none;
                    font-weight: bold;
                    }
                    #lrs li a{
                        height: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        }
@media screen and (max-width:500px){
    #rs a img{
        width:25px;
        height:95%;
    }
}