#footer-item-container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    max-width:93%;
    overflow: hidden;
    width:93%;
    padding:1rem 1%;
    padding-left:6%;
    background-color:black;
    color:white;
    font-size:0.8rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.footer-items{
    list-style:none;
    line-height:1.3rem;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:98%;
    padding:1rem;
    padding-left:0px;
    margin:0px;

}
.footer-items li a{
    color:grey;
    padding:0.3rem;
}
.footer-items li img{
    border:1px solid rgba(250,250,250,0.15);
    border-radius:50%;
}
.footer-items li a:hover{
    background-color:white;
    color:rgba(0,0,0,0.8);
}

.footer-items li span{
    display:inline-block;
    text-align:center;
    background-color:white;
    color:rgba(0,0,0,0.8);
    width:1rem;
    height:1rem;
    border-radius:50%;
    padding:7px;
    margin-right:5px;
}

.item-container{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
}


@media screen and (min-width:500px){
    #footer-item-container{
        flex-direction:row;
        align-items:flex-start;
        justify-content: flex-start;
        padding-left:6%;
        width:93%;
        gap:2rem;
        flex-wrap: wrap;
    }
    .item-container{
        padding-bottom:2rem;
    }
    
    .footer-items{
        max-width:250px;
        width:250px;
    }
}
@media screen and (max-width: 600px) {
    .item-container,.footer-items {
      max-width: 100%;
      width: 100%;
    }
  }
