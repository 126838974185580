.header{
    display:block;
}
#loader{
    display:"none";
    margin-top:"20px"
}
.forConnexion{ 
    /* pour forcer un truc bizare: le height du meme css de l'id (=allways) change selon seulement qu'on ait changé de page alors qu'il  n'y a aucun autre css aui intervient */
    height:23.7px !important;
}
.connexionContainer{
    width:40%;
    margin:15% 30%;
    padding:2%;
    background-color:rgba(255,255,255,.7);
    border:1px solid blue;
    border-radius:5%;
    /* box-shadow:-10px 6px grey,5px 12px grey; */
    margin-bottom:3%;
    margin-top:1%;
    }
.connexionContainer h5 span{display:inline-block;
    width:100px;
    height:1.5em;
    margin:10px;
    text-align:center;
    font-size:1em;
    color:white;
    background-color:rgba(200,0,0,.5);
    }
    /* .bg_image{
        background-image: url();
    } */
.sousTitre{
    color:rgba(0,0,0,.5);
    margin:20px 5%;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size:1em;
    }
.titreConnexion{
    width: 50%;
    margin:1.5% 25%;
    text-align: center;
    color: blue;
    font-weight: bolder;
    letter-spacing: 2px;
    font-size: 20px;
    }

.identifiant,.securite{
    color:grey;
    width:75%;
    height:75%;
    margin:0px;
    padding:0px;
    }

.imgLg{
    max-height:.7em;
    width:70px;
    margin:0px;
    padding:0px
    }
#buttonConnectClick{
    color:white;
    text-align:center;
    padding:5px 0px;
    background-color:red;
    display:none;
    }
 @media screen and (max-aspect-ratio:8/9){
        #div-connexion{
            padding-top:4rem !important;
        }
    }
@media screen and (max-width:600px){
        .connexionContainer{
            width:80%;
            margin:10px 10%;
            margin-left:8%;
            }
        .titreConnexion{
            padding-top:20px;
            width:98%;
            margin:0px 1%;
            }
        /*#div-connexion{
            height:"95vh",
        }*/
    }
/*@media screen and (max-width:1000px){
        #div-connexion{
            height:"95vh",
        }
    }*/
