
.nous{
    display: flex;
    flex-direction: column;
    padding: 5px 2px;
    padding-top:54px;
    margin:0px;
    }
.noustronc{
    display: flex;
    flex-direction: row;
    padding:0px;
    padding-top: 5px;
    margin:0px;
    margin-top:50px;

    }
.secondebar{
    z-index: 90;
    width: 100%;
    height: 50px;
    margin:0px;
    /* 2px 0px; */
    /* margin-top:0px; */
    position: fixed;
    background-color: whitesmoke;
    /* border: .1px solid brown; */
    border-bottom: .25px dotted brown;
    }
    .secondebar ul{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin:0px;
        padding: 0px;
        }
        .secondebar ul li{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            height: 70%;
            cursor:pointer;
            border-bottom:2px solid brown;
            }
            .secondebar a:hover,.secondebar a:active{
                    border:.1px solid brown;
                    border-bottom:5px solid white;
                    background-color: brown;
                    font-weight: bold;
                    color:white;
                    }
                    .secondebar a{
                        width: 98%;
                        text-align: center;
                        font-weight: bold;
                        color:rgba(0,0,0,0.62);
                        text-decoration: none !important;
                        text-decoration: none !important;
                        list-style-type: none;
                        }

.noussidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:30%;
    max-height: 550px;
    margin-left:10px;
    }
    .noussidebar-membres{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        height: fit-content;
        margin:0px;
        padding:0px;
        overflow-x: scroll;
        }
    .detailsmembre{
        width:70% ;
        background-color: white;
        height: flex;
        padding:10px;
        margin-left:10px;
        display: none;
        flex-direction: column;
        align-items: center;
        }
        .memberCard{
            float: left;
            width:92%;
            margin:2%;
            padding: 2%;
            height:fit-content;
            border: 1px solid rgba(0,0,0,.1);
            box-shadow: -4px 6px 6px grey, 6px 2px 6px grey;
            }
            .memberCard img{
                width: 100%;
                height: 100%;
                margin:0%;
                padding:0px;
                }
            .etatCivil{
                position:relative;
                top: 2px;
                width: 100%;
                height: 30%;
                margin:0%;
                display: flex;
                flex-direction: column;
                align-items: center;
                }
                .etatCivil ul{
                    width: 98%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    margin: 0%;
                    padding: 1%;
                    }
                    .etatCivil img{
                        width:50%;
                        height:50%;
                        margin:4px 0px;
                        padding:15%;
                        text-align: center;
                        border:.1px solid white;
                        border-radius: 50%;
                        box-shadow: 4px -4px 6px grey inset, -4px 4px 6px white inset;
                        }
                        .etatCivil img:hover{
                            padding:12%;
                            box-shadow: 1px -1px 6px grey inset, -4px 4px 6px white inset;
                            }
                    .etatCivil ul li{
                        text-decoration: none;
                        list-style-type: none;
                        font-weight: bold;
                        text-align: center;
                        font-family: Verdana, Geneva, Tahoma, sans-serif;
                        color:blue;
                        }
                        .secondebar Link{
                            text-decoration: none;
                        }
                        .etatCivil p,.apropos p{
                            margin:0px;
                            padding: 0px;
                            text-align:justify;
                            }
                            .etatCivil p span{
                                font-weight: bold;
                                }
        .apropos{
                padding: 5%;
                padding-top:3%;
                margin:0px;
                font-family: 'Courier New', Courier, monospace;
                word-break: normal;
                }


.membre{
    position: relative;
    width: 100%;
    height:70px;
    background-color: white;
    border: .5px dotted grey;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    }
    .membre:hover{
        transition: .2s ease-in-out;
        width: 97%;
        height:65px;
        border-left:4px dotted brown;
        border-right:4px dotted brown;
        /* box-shadow: 20px 0px white, -20px 0 rgb(170, 5, 5); */
        }
    .membre img{
        border: 2px solid brown;
        width: 16%;
        height: 80%;
        border-radius: 50%;
        padding:1px;
        margin: 4px;
        }

    .membre ul{
        width: 70%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 2px;
        margin: 6px 0px;
        }
        .membre ul li{
            text-decoration: none;
            list-style-type:none;
            font-size: 12px;
            color: grey;
            border-left: 1px rgba(255,0,0,.3) solid;
            padding-left: 3px;
            }
            .membre ul .prenomNom{
                    color:black !important;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 25px;
                    }
    #twitterAList{
        width:30px;
        height:30px;
        border:.5px solid white;
        float:right;
        position: absolute;
        right: 4px;
        padding:5px;
        top:-2px;
        box-shadow: 4px -4px 5px grey inset, -4px 4px 6px white inset;
        }
        #twitterAList:hover{
            padding:2px;
            /* box-shadow: 2px -2px 1px brown inset, -2px 2px 1px white inset; */
            box-shadow: 1px -1px 3px grey inset, -4px 4px 6px white inset;
        }
@media screen and (max-width:1000px){
    .noussidebar{
        width: 45%;
        }
    .detailsmembre{
        width: 55%;
        }
    }
@media screen and (max-width:700px){
    .noustronc{
        display: flex;
        flex-direction: column;
        padding: 2px;
        }
    .noussidebar,.secondebar{
        width: 97%;
        margin:1%;
        }
    .detailsmembre{
        display:none;
        width: 100%;
        margin: 0px;
        padding:0px;
        padding-top:2%;
        }
    }
@media screen and (max-width:600px){
    .secondebar{
        margin-top:0px;
        position: fixed;
        }
    #twitterAList{
        right:4px;
        }
    }

