.sections{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    width: 100%; 
    min-height:100%;
    
    /* changés */
    padding: 0px;
    padding-top:56px;
    }

@media screen and (min-width:900px){
    #filigrane{
        display:none;
    }
    /* .sections{
        flex-direction: row-reverse;
        align-items: flex-start;
        align-items: left;
        justify-content: space-between;
        width: 100%;
        min-height:100%; 
        margin: 0px;
        margin: 2% 1%;
        padding: 0px;
        }  */
    }
/* @media screen and (max-width:600px) {
    .sections{
        margin-top:0px;
        }
    } */