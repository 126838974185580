#contacts{
    margin:auto;
    padding:auto;
    padding-top:0px;
    margin-top:5rem;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    width:60%;
    height:40vh;
    max-width: 700px;


}
#contacts-partenaires{
    background:linear-gradient(to left bottom, white,rgba(0,90,90,0.8));
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:flex-start;
    padding:1%;
    width:98%;
    height:fit-content;
    margin-top:50px;

}
#partenaires{
    width:18%;
    margin-top:2rem;
    margin-right:6%;
}
@media screen and (max-width:1000px){
    #partenaires{
        display:none;
    }
    #contacts{
        width:80%;
    }
    #rs{
        width:20%;
    }
}
@media screen and (max-width:500px){
    #contacts-partenaires{
        min-width:98% !important;
        max-width:98% !important;
        width:98% !important;
    }
    #contacts{
        width:334px !important;
        /* padding-right:1vw; */
    }
    #rs{
        min-width:65px !important;
        width:65px !important;
    }
    .lnum-ul-li-span{
        margin:6px !important;
        /* text-align:left; */
    }
    #liste_numeros li:nth-child(1){
        font-size:0.8rem;
        /* text-align:left; */
    }
}

@media screen and (max-width:400px){
    #contacts-partenaires{
        min-width:98% !important;
        max-width:98% !important;
        width:98% !important;
        overflow:scroll !important;
    }
    #contacts{
        width:334px !important;
        /* padding-right:1vw; */
    }
    #rs{
        min-width:65px !important;
        width:65px !important;
    }
}

@media screen and (max-width:100px){
    #contacts{
        flex-direction:column;
        gap:1rem
    }
}