#items-mere{
    margin-top:50px;
}
#ul-rub{
    padding:0px;
    list-style:none;
    text-decoration:none;
    line-height:3rem;
}
.div-span{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    color:blue;
    font-weight:bold;
}
#carousel-map{
    --ref-width:60vw;
    @media screen and (max-width:1000px){
        --ref-width:96vw;
    }
    position:relative;
    width:var(--ref-width) !important;
    margin:1rem auto !important;
    height:calc(var(--ref-width)*0.7) !important;
}

.karouselDiv{
    --ref-width:40vw;
    @media screen and (max-width:1200px){
        --ref-width:66vw;
    }
    @media screen and (max-width:850px){
        --ref-width:96vw;
    }

    position:relative;
    width:var(--ref-width) !important;
    margin:1rem auto !important;
    height:calc(var(--ref-width)*0.7) !important;
    .carousel,.carousel-inner{
        height:100%;
    }
    /* .carousel-inner{
        height:100%;
    } */
    .carousel-item{
        width:98% !important;
        height:100%;
    }
    .carousel-item img{
        height:98% !important;
    }
}

@media screen and (max-width:1200px){
    .itemsCampagnes{
        width:76% !important;
        flex-direction:column !important;
        justify-content:flex-start !important;
        align-items: center !important;
        margin:0px 2% !important;


    }
    .entriesCampagnes{
        width:100% !important;
        padding:0px !important;
        margin-bottom:0.5rem;

    }
    #ul-rub{
        display:flex;
        flex-direction:row !important;
        justify-content:space-between;
        align-items: center !important;
        padding:auto 0px !important;
        /* gap:2rem; */
    }
    .tablesCampagnes{
        width:98% !important;
        margin:0px !important;

    }
    .partenairesCampagnes{
        /* display:inline-block; */
        width:25% !important;

    }
    .span-svg{
        margin-left: 0.5rem !important;
    }
    .div-span{
        padding-right: 1rem;
        padding-left:1rem;
        border-right:1px solid grey;
    }
}
@media screen and (max-width:850px){
    .itemsCampagnes{
        width:98% !important;
        margin:0px 1% !important;
    }
    .partenairesCampagnes{
        display:none !important;
    }
}
@media screen and (max-width:800px){
    /* #items-mere{
        min-width:100vw !important;
        max-width:100vw !important;
        width:100vw !important;
        align-items: flex-start !important;
        overflow:scroll !important;
        justify-content:flex-start !important;
        
    }
    #parent-itemsCampagnes{
        min-width:620px !important;
        width:625px !important;
    } */
    /* .entriesCampagnes,.tablesCampagnes{
        min-width:100% !important;
        width:100% !important;
        overflow:scroll !important;

        } */
        .entriesCampagnes,.tablesCampagnes{
                min-width:800px !important;
                width:800px !important;
                padding:8px !important;
            }
        #ul-rub{
                justify-content:flex-start;
                height:2.5rem !important;
            }
    /* .partenairesCampagnes{
            max-width:100%;
            width:100%;
        } */
    /* #footer-item-container{
        width:605px !important;
    } */
}