/* .meta-data{ */
    /* display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items:center;
    background-color: rgba(0,0,0,0.2);
    (200,0,0,0.8);
    width: 100%;
    margin:0.5% 0.1%;
    height: auto;
    padding:0%; */
    /* position:sticky;
    top:90vh; */
    /* } */
.noussoutenir{
    height:1.5rem;
    width:fit-content;
    padding:0.5rem 1rem;
    margin:0px;
    font-weight:bold;
    color:white;
    letter-spacing: 2px;
    position:fixed;
    right:10px;
    text-decoration:none;
    animation: 1s infinite alternate slidein;
    bottom:10vh;
    border-radius:20px;
    background-color:green;
    z-index:10000;
    }
    #reso{
        width:70%;
        max-width: 300px;
        background-color:rgba(0,0,0,.2);
        margin:auto;
        position:sticky;
        bottom:0px;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        box-shadow:2px 0px 4px rgba(0,0,0,.2),-2px -1px 2px rgba(0,0,0,.2);
        z-index:10000;
        /* margin-top:70vh; */
    }
        /* #textare{
            color: #000;
            padding:10px 2%;
            margin: 0px;
            height: 120px;
            } */
            .reseaux_Sociaux,.opinion{
                margin: 0px;
                width: 92%;
                max-width: 700px;
                color: #000;
                border-top:1px solid grey;
                /* margin: .5em; */
                /* background-color: rgba(200,0,0,0.8); */
                }
            .reseaux_Sociaux{
                display:flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: fit-content;
                background-color:rgba(250,250,250,.2); 
                margin-top: 1.5rem;
                
                }
                .reseaux_Sociaux p{
                    width:100%;
                    height: 3rem;
                    color: rgba(0,0,0,.5);
                    text-shadow:4px 4px 1px rgba(250,250,250,1),-1px -1px 1px rgba(0,0,0,0.2);
                    text-align: center;
                    align-content: center;
                    font-size: 20px;
                    padding:auto;
                    border-bottom:1px solid white;
                    padding-top:1rem;
                    }
                .reseaux_Sociaux a img{
                    width:30px;
                    height:100%;
                    border:1px solid white;
                    padding:2px;
                    margin:2px;
                    border-radius: 50%;
                    }
                    #liste_reseaux_sociaux,#liste_numeros{
                        width:100%;
                        /* height: 80%; */
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        list-style-type:none;
                        margin:0px;
                        /* 15px 0px; */
                        padding: 0px;
                       
                        }
                    #liste_reseaux_sociaux{
                        margin-top:7px;
                        margin-bottom:0px;
                        max-width: 500px;
                        margin:auto;
                        }
                    #liste_numeros{
                        flex-direction: column;
                        margin:0px;
                        height:100%;
                        }
                        #liste_reseaux_sociaux li{
                            width:25%;
                            color: #000;
                            text-align: center;
                            }
                            #liste_reseaux_sociaux a,#liste_numeros li{
                                display:inline-block;
                                height:100%;
                                width:100%;
                                color: rgba(0, 0, 0, 1);
                                text-shadow: .5px .5px white;
                                letter-spacing: 2px;
                                line-height: 2em;
                                text-align: center;
                                text-decoration: none;
                                font-weight: bold;
                                }
                                #liste_reseaux_sociaux li a{
                                    height: 100%;
                                    text-align: center;
                                    }
                                    #liste_reseaux_sociaux li a{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        align-items: center;
                                        }

                .suivre{
                    padding: 0%;
                    margin: 0em;
                    color: rgb(119, 0, 255);
                    font-weight: bold;
                    font-weight: 18px;
                }
                .opinion{
                    display:flex;
                    flex-direction: column;
                    align-content: center;
                    height: 65%;
                    margin-top: 0px;
                }
                .copyright{
                    width:79%;
                    max-height:fit-content;
                    background-color:white;
                    font-size:0.7em;
                    display:flex;
                    flex-direction:row;
                    justify-content:center;
                    /* border-top: 1px solid rgba(0,0,0,.2); */
                    box-shadow:10px 0px 8px rgba(0,0,0,.2);
                    padding:2px 10%;
                    color:grey;
                    }
            /* iframe{
                width: 100px;
                height: 100px;
            } */
                /* .ecrire{
                    width:100%;
                    margin: 0px;
                    padding:0px;
                            10px 0px;
                    color: rgb(150,0,0);
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                    } */
                    /* .submit_idee{
                        padding: .2em;
                        font-size: 16px;
                        width: 50%;
                        color: #000;
                        margin:1.5em 0px;
                        margin-left:25%;
                        color: rgba(255, 255, 255, 0.7);
                        background-color: rgba(0, 0, 255, 0.7);
                    } */
/* @media only screen and (min-width:600px) {

} */
@keyframes slidein {
    from{
        box-shadow:1px 1px 10px rgba(250, 250, 250, 1) inset,-1px -1px 10px rgba(250, 250, 250, 1) inset;
        right:.75rem;
        font-size:.9rem;
        height:1rem;
    }
    to{
        right:.5rem;
        font-size:1rem;
        height:1.5rem;
        /* box-shadow:1px 1px 10px rgba(250, 250, 250, 1) inset,-1px -1px 10px rgba(250, 250, 250, 1) inset; */
        
    }
}