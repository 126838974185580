    #topbar{
        background-color:rgb(150,0,0);
    }
    /* .activated{
        background-color:rgba(55,0,0,0.2) !important;
        color:white !important;
        } */
    
    #allways{
            width:92%;
            height:40px;
            background-color:white;
            color:rgba(0,0,0,0.5);
            display:flex;
            flex-direction:row;
            justify-content:flex-end;
            align-items:center;
            gap:1.7rem;
            padding:10px 4%;
            font-size:0.9rem;
            i{
                padding:0.15rem;
                border-radius: 5px;
            }
            a{
                text-decoration:none;
                /* color:rgba(0,0,0,0.5); */
                color:white;
                font-family:'Times New Roman', Times, serif;
            }
            a:hover{
                transform:scale(1.05);
                color:rgba(0,0,0,0.7);
                transition:0.4s ease;
            }
            @media screen and (max-width:500px){
                gap:1.1rem
            }
            .bi-twitter-x{
                color:rgba(0,0,150,0.4);
            }
            .bi-twitter-x:hover{
                border:1px solid rgba(0,0,150,0.4);
            }
            .bi-facebook{
                color:blue;
            }
            .bi-facebook:hover{
                color:blue;
                border:1px solid blue;
            }
            .bi-youtube{
                color:red;
            }
            .bi-youtube:hover{
                border:1px solid red;
            }
            @media screen and (max-width:345px){
                .left{position:initial;}
                
            }
        }
        .left{
            position:absolute;
            left:4%;
            background-color:transparent !important;
            color:rgba(0,0,100,0.6) !important;
        }
        .selfLeft{
            height:1rem;
            background-color:black;
            padding:0.15rem 0.6rem;
            border:1px solid rgba(0,0,100,0.6);
            border-radius: 10px;
            padding:0.25rem 0.7rem;
            color:white;
        }
    .top-bar{
        /* nouveau */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        top:0px;
        /* fin nouveau */
        padding: 4px 0px;
        padding-top:0px;
        height: 100px;/*50px*/
        width: 100%;
        background-color:rgb(150, 0 ,0);
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: .8em;
        text-align: center;
        position: fixed;
        z-index: 100;
        box-shadow:2px 2px 4px grey;
        }
    .contient{
        display: flex;
        flex-direction:row;
        align-items:center;
        width:100%;
        height:60px;
        margin:auto;
        padding:auto;
        padding-top:4px;
        max-width: 1350px;
        min-width: 260px;
    }
            
        #right,#left{
            display: flex;
            flex-direction: row;
            align-items: center;
            min-height: 100%;
        }
            #right{
                justify-content: flex-start;
                width:18%;
                right:0px;
                margin-left: 2%;
                }
                #logo{
                    text-decoration: none;
                    }
                    .logo{
                        width: 100px;
                        height: 35px;
                        border: none;
                        padding:auto 5px;
                        border-radius:10%;
                        margin: auto;
                        }
                        .logo:hover{
                            transition:10s;
                            }
            
            #left{
                width: 75%;
                min-height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-right: 3px;
                }
                .nav-items,#menu_ico{
                    padding: 3px;
                    }
                    .nav-items a,#menu_ico{
                        color: rgba(253, 252, 252,0.7);
                        text-decoration: none;
                        padding:4px 9px;
                        letter-spacing: 1px;
                        margin-top:15%;
                        visibility: visible;
                        }
                        .nav-items a:hover,#menu_ico a:hover{
                            color: rgba(253, 252, 252,1);
                            border-bottom:solid 2px #fff;
                            }
                        .nav-items a:active,#menu_ico a:active{
                            background-color: rgba(253, 252, 252,0.4);
                            color: rgba(0, 0, 0, 1);
                            border-bottom:solid 2px #fff;
                            border-radius: 2px;
                            }
                        #menu_ico img{
                            width: 3em;
                            height: 1.4em;
                            }
                            #menu_ico{
                                visibility: hidden;
                                display: inline-block;
                                width: 0px;
                            }

 /* @media screen and (min-width:1000px){
                        .header{
                            min-height: 80vh;
                        }

                    } */
@media screen and (min-width:750px){
    /* 60px */
                        /* .header{
                            min-height: 80vh;
                        } */
                        .top-bar{
                            position: fixed;
                            /* top:8px; */
                        }
                       
                    }
@media screen and (max-width:750px){
    /* 600px */
                        /* .header{
                            min-height:0vh;
                        } */
                        /* .top-bar{
                            margin-top:-5px;
                        } */
                        .nav-items,.nav-items a{
                            visibility: hidden;
                            width: 0px;
                            }
                        #menu_ico{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 1.7em;
                            min-height: 80%;
                            visibility: visible;
                        }
                        #right{
                            width:16%;
                            margin-left: 4%;
                            }
                         #left{
                            margin-right: 5%;
                         }
                         /* #seconnecter{
                            min-width:100px;
                         } */
                       
                    }

